import { LoadingOutlined } from '@ant-design/icons';

function LoadingPage() {

    return (
        <div className='full-page-absolute' style={{backgroundColor:'white'}}>
            <div className='display-flex-center'>
                <LoadingOutlined style={{fontSize:'40px',color:'rgba(84,51,255, 1)'}} />
            </div>
        </div>
    )
}

export default LoadingPage;