import { FacebookOutlined, InstagramOutlined, LinkedinOutlined, RightOutlined, TwitterOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import logo from '../../image/logo/logo512.png';


function MainFooter({clickLaunch}) {
    return(
        <div>
            <div className="mb-4">
                <Link to='/'className="cursor-pointer mb-0">
                    <div className="flex items-center gap-1">
                        <div>
                            <img src={logo} className="w-10 rounded-lg border" />
                        </div>
                        <h1 className="text-ecalmers-full m-0 leading-none">eCalmersAI</h1>
                    </div>
                </Link>
            </div>
            <div className="w-full pb-16 grid grid-cols-1 md:grid-cols-5 gap-8 md:gap-2 border-b text-left">
                <div className="col-span-2 text-left flex flex-col gap-5 h-full">
                    <div className="flex-1 flex flex-col gap-5">
                        <div className="text-slate-800 pl-1">
                            <h2 className="text-2xl m-0 leading-tight">
                                Launch in Minutes.<br/>
                                Not Days.
                            </h2>
                        </div>
                        <div onClick={clickLaunch} className="bg-ecalmers-full text-white text-base font-semibold rounded-full py-1 px-4 w-fit cursor-pointer hover-scale-xs">
                            Launch Today
                        </div>
                    </div>
                    <div>
                        <Link to="/blog" className="w-fit">
                            <div className="w-fit text-ecalmers-700 hover:underline">
                                Read Our Blog <RightOutlined />
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="flex flex-col gap-4 w-full">
                    <div>
                        <h3 className="text-2xl m-0 font-semibold text-slate-800 leading-tight">
                            Launch
                        </h3>
                    </div>
                    <div className="text-base flex flex-col gap-3 text-slate-600">
                        <div>
                            <a href=''>
                                Socials
                            </a>
                        </div>
                        <div>
                            <a href=''>
                                Blogs
                            </a>
                        </div>
                        <div>
                            <a href=''>
                                Emails
                            </a>
                        </div>
                        <div>
                            <a href=''>
                                ProductHunt
                            </a>
                        </div>
                        <div>
                            <a href=''>
                                Everything
                            </a>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-4">
                    <div>
                        <h3 className="text-2xl m-0 font-semibold text-slate-800 leading-tight">
                            Features
                        </h3>
                    </div>
                    <div className="text-base flex flex-col gap-3 text-slate-600">
                        <div>
                            <a href=''>
                                Launch a Product
                            </a>
                        </div>
                        <div>
                            <a href=''>
                                Launch a Feature
                            </a>
                        </div>
                        <div>
                            <a href=''>
                                Create a Post
                            </a>
                        </div>
                        <div>
                            <a href=''>
                                Write a Blog
                            </a>
                        </div>
                        <div>
                            <a href=''>
                                Write an Email
                            </a>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-4">
                    <div>
                        <h3 className="text-2xl m-0 font-semibold text-slate-800 leading-tight">
                            Applications
                        </h3>
                    </div>
                    <div className="text-base flex flex-col gap-3 text-slate-600">
                        <div>
                            <a href=''>
                                For Small Businesses
                            </a>
                        </div>
                        <div>
                            <a href=''>
                                For eCommerce
                            </a>
                        </div>
                        <div>
                            <a href=''>
                                For Startups
                            </a>
                        </div>
                        <div>
                            <a href=''>
                                For Personal Brands
                            </a>
                        </div>
                        <div>
                            <a href=''>
                                For Good
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full flex items-center text-slate-700 text-left py-8">
                <div className="flex-1">
                    © 2024 eCalmersAI
                </div>
                <div className="flex items-center gap-2 text-lg">
                    <a href='https://www.facebook.com/eCalmers' target="_blank">
                        <div className="border-ecalmers-full text-ecalmers-full rounded-full aspect-square p-2 leading-none cursor-pointer hover-scale-sm">
                            <FacebookOutlined />
                        </div>
                    </a>
                    <a href='https://www.linkedin.com/company/ecalmersai' target="_blank">
                        <div className="border-ecalmers-full text-ecalmers-full rounded-full aspect-square p-2 leading-none cursor-pointer hover-scale-sm">
                            <LinkedinOutlined />
                        </div>
                    </a>
                    <a href='https://x.com/eCalmers_AI' target="_blank">
                        <div className="border-ecalmers-full text-ecalmers-full rounded-full aspect-square p-2 leading-none cursor-pointer hover-scale-sm">
                            <TwitterOutlined />
                        </div>
                    </a>
                    <a href='https://www.instagram.com/ecalmers_ai' target="_blank">
                        <div className="border-ecalmers-full text-ecalmers-full rounded-full aspect-square p-2 leading-none cursor-pointer hover-scale-sm">
                            <InstagramOutlined />
                        </div>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default MainFooter;