import { useOutlet } from "react-router-dom";
import { DashboardProvider, useDashboardContext } from "../../components/Context/DashboardContext";
import styles from './dashboard.module.css';
import DashboardSidebar from "./Components/DashboardSidebar";
import DashboardNavbar from "./Components/DashboardNavbar";
import { useState } from "react";
import LoginModal from "../../components/Modal/LoginModal";
import { Layout } from "antd";
import Sider from "antd/es/layout/Sider";
import { Content, Header } from "antd/es/layout/layout";
import DashboardMobileBottomNav from "./Components/DashboardMobileBottomNav";


export const DashboardLayout = () => {
    const {showSidebar,toggleSidebar} = useDashboardContext();
    const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
    
    const clickLogin = () => {
        setIsLoginModalVisible(true);
    }
    const closeModal = () => {
        setIsLoginModalVisible(false);
    }

    const outlet = useOutlet();
    return (
        <DashboardProvider>
            <Layout className="full-page-absolute">
                <Sider theme="light" width={'250px'} className='h-screen w-full bg-[#F5F5F5] no-mobile' >
                    <DashboardSidebar clickLogin={clickLogin} />
                </Sider>
                <Layout className='h-screen w-full bg-[#F5F5F5] relative' >
                    <DashboardNavbar clickLogin={clickLogin} />
                    <Content className='absolute top-[65px] left-0 right-0 bottom-0 overflow-y-scroll hide-scrollbar'>
                        {outlet}
                    </Content>
                </Layout>
                <LoginModal isLoginModalVisible={isLoginModalVisible} closeModal={closeModal} />
                <DashboardMobileBottomNav />
            </Layout>
        </DashboardProvider>
    );
};