import { PicCenterOutlined, PlusOutlined, RocketOutlined, SettingOutlined } from '@ant-design/icons';
import { useDashboardContext } from '../../../components/Context/DashboardContext';
import { useEffect } from 'react';

function MobileNavButton({onClick,active,icon}) {
    return(
        <div onClick={onClick} className={`p-2 rounded-full leading-none text-xl ${active ? 'text-white bg-ecalmers-full' : 'text-ecalmers-full border'}`}>
            {icon}
        </div>
    )
}

function DashboardMobileBottomNav({setIsDashboardSidebarDrawerVisible=false}) {
    const {activeTab,setActiveTab,changeTab} = useDashboardContext();

    const clickMenuItem = (value) => {
        changeTab(value);
        if(setIsDashboardSidebarDrawerVisible){
            setIsDashboardSidebarDrawerVisible(false);
        }
    }

    useEffect(() => {
        const path = window.location.pathname.split('/');        
        if(activeTab !== path[path.length - 1]){
            setActiveTab(path[path.length - 1]);
        }
    },[]);

    return(
        <div className='absolute md:hidden bottom-8 left-0 right-0 flex justify-center'>
            <div className='flex items-center bg-white rounded-full w-fit p-2 gap-4 shadow-lg'>
                <MobileNavButton onClick={() => clickMenuItem('launch')} active={['launch'].includes(activeTab)} icon={<RocketOutlined/>} />
                <MobileNavButton onClick={() => clickMenuItem('content')} active={['content','feed-page'].includes(activeTab)} icon={<PicCenterOutlined/>} />
                <MobileNavButton onClick={() => clickMenuItem('create')} active={activeTab === 'create'} icon={<PlusOutlined />} />
                <MobileNavButton onClick={() => clickMenuItem('settings')} active={activeTab === 'settings'} icon={<SettingOutlined/>} />
            </div>
        </div>
    )
}

export default DashboardMobileBottomNav;