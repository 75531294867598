import axios from "axios";
import Resizer from "react-image-file-resizer";

const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/${process.env.REACT_APP_API_KEY}`;

const resizeFile = (file) => new Promise(resolve => {
    Resizer.imageFileResizer(file, 800, 800, 'JPEG', 100, 0,
        uri => {
        resolve(uri);
        }, 'file' );
});

class ContentServices {
    async postImage({image, name, user=false}) {
        let formData = new FormData();

        const imageResized = await resizeFile(image);        
        formData.append('image', imageResized, `${name}`);

        let configuration = {
            credentials: 'include',
            method: "post",
            url: API_URL + "/image",
            data: formData,
        };

        if(user){
            configuration['headers'] = {"Authorization" : `Bearer ${user.access}`};
        }
    
        // Send POST Query with Login Info
        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response;
            //error = new Error();
        });
    }

    createEditBlog({values, user=false, blog_id=false}){
        let configuration = {
            credentials: 'include',
            method: "post",
            url: API_URL + "/blog",
            data: values,
        };

        if(user){
            configuration['headers'] = {"Authorization" : `Bearer ${user.access}`};


            if(blog_id){
                configuration['method'] = 'patch';
                configuration['url'] = API_URL + `/blog/${blog_id}`;
            }

            return axios(configuration)
            .then(response => {
                return response.data;
            })
            .catch((error) => {
                return error.response;
                //error = new Error();
            });
        }
        else{
            return false;
        }
    }

    getBlogPost({brand_key,blog_name}){

        var configAPIUrl = API_URL + `/blog/post/${brand_key}/${blog_name}`

        const configuration = {
            credentials: 'include',
            method: "get",
            url: configAPIUrl
        };

        // Send POST Query with Login Info
        return axios(configuration)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            error = new Error();
        });

    }

    getBlogList({page=0, limit=15, ordering='-updated_at',  user_id=false, brand_id=false, category=false, channel=false, filters=false}) {

        const offset = limit * page || 0;

        var configAPIUrl = API_URL
        if (offset > 0){configAPIUrl = API_URL + `/blog/list?limit=${limit}&offset=${offset}&ordering=${ordering}&is_active=True`}
        else { configAPIUrl = API_URL + `/blog/list?limit=${limit}&ordering=${ordering}&is_active=True` };

        if(user_id){
            configAPIUrl = configAPIUrl + `&user_id=${user_id}`;
        }
        if(brand_id){
            configAPIUrl = configAPIUrl + `&brand_id=${brand_id}`;
        }
        if(channel){
            configAPIUrl = configAPIUrl + `&channel=${channel}`;
        }
        if(category) {
            configAPIUrl = configAPIUrl + `&category__key=${category}`;
        }
        if(filters?.search){
            configAPIUrl = configAPIUrl + `&search=${filters?.search.join(',')}`;
        }

        const configuration = {
            credentials: 'include',
            method: "get",
            url: configAPIUrl,
        };

        // Send POST Query with Login Info
        return axios(configuration)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            error = new Error();
        });
    }

    getBlogCategoryList({brand_id}) {

        const configuration = {
            credentials: 'include',
            method: "get",
            url: API_URL + `/blog/categories/${brand_id}`
        };

        // Send POST Query with Login Info
        return axios(configuration)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            error = new Error();
        });
    }

    createEditEmail({values, user=false, email_id=false}){
        let configuration = {
            credentials: 'include',
            method: "post",
            url: API_URL + "/email",
            data: values,
        };

        if(user){
            configuration['headers'] = {"Authorization" : `Bearer ${user.access}`};

            if(email_id){
                configuration['method'] = 'patch';
                configuration['url'] = API_URL + `/email/${email_id}`;
            }

            return axios(configuration)
            .then(response => {
                return response.data;
            })
            .catch((error) => {
                return error.response;
                //error = new Error();
            });
        }
        else{
            return false;
        }
    }
    getEmailList({page=0, limit=18, ordering='-updated_at',  user_id=false, brand_id=false, category=false, filters=false}) {

        const offset = limit * page || 0;

        var configAPIUrl = API_URL
        if (offset > 0){configAPIUrl = API_URL + `/email/list?limit=${limit}&offset=${offset}&ordering=${ordering}&is_active=True`}
        else { configAPIUrl = API_URL + `/email/list?limit=${limit}&ordering=${ordering}&is_active=True` };

        if(user_id){
            configAPIUrl = configAPIUrl + `&user_id=${user_id}`;
        }
        if(brand_id){
            configAPIUrl = configAPIUrl + `&brand_id=${brand_id}`;
        }
        if(category){
            configAPIUrl = configAPIUrl + `&category__key=${category}`;
        }
        if(filters?.search){
            configAPIUrl = configAPIUrl + `&search=${filters?.search.join(',')}`;
        }

        const configuration = {
            credentials: 'include',
            method: "get",
            url: configAPIUrl,
        };

        // Send POST Query with Login Info
        return axios(configuration)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            error = new Error();
        });
    }

    getEmailCategoryList({brand_id}) {

        const configuration = {
            credentials: 'include',
            method: "get",
            url: API_URL + `/email/categories/${brand_id}`
        };

        // Send POST Query with Login Info
        return axios(configuration)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            error = new Error();
        });
    }
}
export default new ContentServices();