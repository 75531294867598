import React, {Suspense} from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Layout } from 'antd';
import './App.css';
import './style/index.css';
import './output.css';
import LoadingPage from './containers/LoadingPage';
import { Content } from 'antd/es/layout/layout';
import { AuthProvider } from './utils/useAuth';
import { DashboardProvider } from './components/Context/DashboardContext';
import { DashboardLayout } from './containers/Dashboard/DashboardLayout';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import EcalmersBlogsPage from './containers/Dashboard/Pages/Blog/EcalmersBlogsPage';
import BlogPage from './containers/Dashboard/Pages/Blog/BlogPage';
import BlogsPage from './containers/Dashboard/Pages/Blog/BlogsPage';

const SuccessPage = React.lazy(() => import("./containers/Landing/SuccessPage"));
const DashboardBrandSelectPage = React.lazy(() => import("./containers/Dashboard/Pages/Brands/DashboardBrandSelectPage"));
const DashboardBrandPage = React.lazy(() => import("./containers/Dashboard/Pages/Brands/DashboardBrandPage"));
const DashboardSettingsPage = React.lazy(() => import("./containers/Dashboard/Pages/Settings/DashboardSettingsPage"));
const FreeBrandGuidePage = React.lazy(() => import("./containers/Onboarding/FreeBrandGuidePage"));
const FreeMarketResearchPage = React.lazy(() => import("./containers/Onboarding/FreeMarketResearchPage"));
const BrandLaunchAIPage = React.lazy(() => import("./containers/Onboarding/BrandLaunchAIPage"));
const DashboardCreatePage = React.lazy(() => import('./containers/Dashboard/Pages/Create/DashboardCreatePage'));
const DashboardSelectCreatePage = React.lazy(() => import("./containers/Dashboard/Pages/Create/DashboardSelectCreatePage"));
const ContentSelectPage = React.lazy(() => import("./containers/Dashboard/Pages/Content/ContentSelectPage"));
const LaunchTemplatePage = React.lazy(() => import("./containers/Dashboard/Pages/Create/LaunchPage"));
const OnboardingFlowPage = React.lazy(() => import("./containers/Onboarding/OnboardingFlowPage"));
const LaunchPage = React.lazy(() => import("./containers/Dashboard/Pages/Create/LaunchPage"));
const LaunchHomePage = React.lazy(() => import("./containers/Landing/LaunchHomePage"));
const LoginPage = React.lazy(() => import("./containers/LoginPage"));
const LaunchSocialsPage = React.lazy(() => import("./containers/Dashboard/Pages/Static/Launch/LaunchSocialsPage"));


function App() {

  return (
    <div className="App">
      <Router>
        <Layout>
          <HelmetProvider>
            <AuthProvider>
              <Content>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{`eCalmersAI - Keep Calm and Enjoy Marketing`}</title>
                    <meta property="og:title" content={`eCalmersAI - Keep Calm and Enjoy Marketing`}/>
                    <meta name="description" content={`eCalmersAI - Keep Calm and Enjoy Marketing`} />
                    <link rel="canonical" href="https://www.ecalmers.com" />
                </Helmet>
                <Routes>
                  <Route path='/' element={<Suspense fallback={<LoadingPage />} ><LaunchHomePage /></Suspense>} />
                  <Route path="dashboard" element={<Suspense fallback={<LoadingPage />} ><DashboardProvider><DashboardLayout /></DashboardProvider></Suspense> }>
                    <Route path="launch" element={<Suspense fallback={<LoadingPage />} ><LaunchPage /></Suspense>} />
                    <Route path="content" element={<Suspense fallback={<LoadingPage />} ><ContentSelectPage /></Suspense>} />
                    <Route path="create" element={<Suspense fallback={<LoadingPage />} ><DashboardSelectCreatePage /></Suspense>} />
                    <Route path="create/new" element={<Suspense fallback={<LoadingPage />} ><DashboardCreatePage /></Suspense>} />
                    <Route path="brands" element={<Suspense fallback={<LoadingPage />} ><DashboardBrandSelectPage /></Suspense>} />
                    <Route path="brand/:brand_key" element={<Suspense fallback={<LoadingPage />} ><DashboardBrandPage /></Suspense>} />
                    <Route path="settings" element={<Suspense fallback={<LoadingPage />} ><DashboardSettingsPage /></Suspense>} />
                  </Route>
                  <Route path="/blog" element={<Suspense fallback={<LoadingPage />} ><EcalmersBlogsPage /></Suspense>} />
                  <Route path="/blog/:brand_key/:blog_name" element={<Suspense fallback={<LoadingPage />} ><BlogPage /></Suspense>} />
                  <Route path="/blog/:brand_key" element={<Suspense fallback={<LoadingPage />} ><BlogsPage /></Suspense>} />
                  <Route path="/launch" element={<Suspense fallback={<LoadingPage />} ><LaunchPage /></Suspense>} />
                  <Route path="/launch/socials" element={<Suspense fallback={<LoadingPage />} ><LaunchSocialsPage /></Suspense>} />
                  <Route path="/launch/producthunt" element={<Suspense fallback={<LoadingPage />} ><LaunchTemplatePage /></Suspense>} />
                  <Route path="/launch/email-marketing" element={<Suspense fallback={<LoadingPage />} ><LaunchTemplatePage /></Suspense>} />
                  <Route path="/launch/blog" element={<Suspense fallback={<LoadingPage />} ><LaunchTemplatePage /></Suspense>} />
                  <Route path='/free-brand-guide' element={<Suspense fallback={<LoadingPage />} ><FreeBrandGuidePage /></Suspense>} />
                  <Route path='/free-market-research' element={<Suspense fallback={<LoadingPage />} ><FreeMarketResearchPage /></Suspense>} />
                  <Route path='/branding-ai' element={<Suspense fallback={<LoadingPage />} ><BrandLaunchAIPage /></Suspense>} />
                  <Route path="/onboarding" element={<Suspense fallback={<LoadingPage />} ><OnboardingFlowPage /></Suspense>} />
                  <Route path='/login' element={<Suspense fallback={<LoadingPage />} ><LoginPage /> </Suspense>} />
                  <Route path='/success' element={<Suspense fallback={<LoadingPage />} ><SuccessPage /> </Suspense>} />
                </Routes>
              </Content>
            </AuthProvider>
          </HelmetProvider>            
        </Layout>
      </Router>
    </div>
  );
}

export default App;
