import { BellOutlined, NotificationOutlined } from "@ant-design/icons"
import { Badge, Dropdown } from "antd"

function NotificationButton({active}) {

    const dropdown = (
        <div className="filter-dropdown-container">
            <div className="filter-dropdown-title">
                Notifications
            </div>
            <div>
                No Notifications yet
            </div>
        </div>
    )

    return(
        <Dropdown
            dropdownRender={() => dropdown}
            trigger={'click'}
            >
            <div className="notification-container display-flex-center">
                <Badge dot={active}>
                    <BellOutlined style={{color:'#FFA412'}} />
                </Badge>            
            </div>
        </Dropdown>
    )
}

export default NotificationButton