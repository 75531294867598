import { Space } from 'antd';
import styles from '../dashboard.module.css';
import DashboardButton from './DashboardButton';
import { PicCenterOutlined, PlusOutlined, RocketOutlined, SettingOutlined } from '@ant-design/icons';
import { useDashboardContext } from '../../../components/Context/DashboardContext';
import { useEffect } from 'react';
import logo from '../../../image/logo/logo512.png';
import ReactGA from 'react-ga4';

function DashboardSidebar({setIsDashboardSidebarDrawerVisible=false}) {
    const {activeTab,setActiveTab,changeTab,showSidebar} = useDashboardContext();

    const clickMenuItem = (value) => {
        changeTab(value);
        if(setIsDashboardSidebarDrawerVisible){
            setIsDashboardSidebarDrawerVisible(false);
        }
        ReactGA.event({
            category: 'navigate',
            action: `click_sidebar_${value}`,
        });

    }

    useEffect(() => {
        const path = window.location.pathname.split('/');        
        if(activeTab !== path[path.length - 1]){
            setActiveTab(path[path.length - 1]);
        }
    },[]);

    return(
        <div>
            <div className={styles.logo_container}>
                <div className="text-align-left pl-[15px]" style={{flex:1}}>
                    <a href='/' target='_blank'>
                        {
                            showSidebar
                            ? <h2 style={{color:'#5662E2',lineHeight:1,margin:0}}>eCalmers AI</h2>
                            :   <div>
                                    <img src={logo} className='w-full' />
                                </div>
                        }
                    </a>
                </div>
            </div>
            <div className={styles.sidebar_menu_container}>
                <Space direction='vertical' style={{width:'100%'}}>
                    <DashboardButton name='Launch' showSidebar={showSidebar} onClick={() => clickMenuItem('launch')} active={['launch'].includes(activeTab)} icon={<RocketOutlined/>} />
                    <DashboardButton name='My Content' showSidebar={showSidebar} onClick={() => clickMenuItem('content')} active={['content','feed-page'].includes(activeTab)} icon={<PicCenterOutlined/>} />
                    <DashboardButton name='Create' showSidebar={showSidebar} onClick={() => clickMenuItem('create')} active={activeTab === 'create'} icon={<PlusOutlined />} />
                    <DashboardButton name='Settings' showSidebar={showSidebar} onClick={() => clickMenuItem('settings')} active={activeTab === 'settings'} icon={<SettingOutlined/>} />
                </Space>
            </div>
        </div>
    )
}

export default DashboardSidebar;