import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga4';
const TRACKING_ID = "G-02692NNJ03"; // your Measurement ID

ReactGA.initialize(TRACKING_ID);
const root = createRoot(document.getElementById('root'));
root.render(
  <App />
);

reportWebVitals();
