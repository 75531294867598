import { PictureOutlined, VideoCameraOutlined } from "@ant-design/icons";


function NoImage({type='image'}) {
    return(
        <div className="w-full h-full bg-slate-200 flex items-center justify-center">
            <div className="text-center text-slate-400">
                <div className="text-3xl">
                    {
                        type === "image"
                        ? <PictureOutlined />
                        : <VideoCameraOutlined />
                    }
                </div>
                <div>
                    {
                        type === "image"
                        ? 'No Image Yet!'
                        : 'No Video Yet!'
                    }
                </div>
            </div>
        </div>
    )
}

export default NoImage;