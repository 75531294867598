import { useEffect } from "react";
import { useAuth } from "../../../utils/useAuth";
import { Avatar, Dropdown, Space } from "antd";
import styles from '../dashboard.module.css';
import { DownOutlined } from "@ant-design/icons";
import { useDashboardContext } from "../../../components/Context/DashboardContext";

function DashboardUser() {
    const {user} = useAuth();
    const {changeTab} = useDashboardContext();

    const dropdown = (
        <div className="filter-dropdown-container">
            <Space direction="vertical" style={{paddingLeft:'5px'}}>
                <div onClick={() => changeTab('brands')}>
                    <a className={styles.dashboard_user_link}>Manage Brands</a>
                </div>
                <div onClick={() => changeTab('settings')}>
                    <a className={styles.dashboard_user_link}>Edit Profile</a>
                </div>
                <div onClick={() => changeTab('logout')}>
                    <a className={styles.dashboard_user_link}>Sign Out</a>
                </div>
            </Space>
        </div>
    );

    return(
        <Dropdown
            dropdownRender={() => dropdown}
            trigger={'click'}
            >
            <div className={styles.dashboard_user_container}>
                <div className={styles.dashboard_user_avatar_container}>
                    <Avatar className={styles.dashboard_user_avatar} shape="square" src={user?.avatar?.image ? user?.avatar?.image : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcReh8NuEJj15o7u5x7dW99KPdSO_rH0LKihMw&usqp=CAU'} />
                </div>
                <div className={`${styles.dashboard_user_text_container} no-mobile`}>
                    <div style={{display:'flex'}}>
                        <div className={styles.dashboard_username}>{user.username}</div>
                        <div className={styles.dashboard_username_dropdown}>
                            <DownOutlined />
                        </div>
                    </div>
                    <div className={styles.dashboard_user_title}>Admin</div>
                </div>
            </div>
        </Dropdown>
    )
}

export default DashboardUser;