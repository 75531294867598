import { Drawer } from "antd";
import DashboardSidebar from "./DashboardSidebar";
import { CloseOutlined } from "@ant-design/icons";
import styles from '../dashboard.module.css';

function DashboardSidebarDrawer({clickLogin, isDashboardSidebarDrawerVisible, setIsDashboardSidebarDrawerVisible}){
    
    return(
        <Drawer
            placement={'left'}
            closable={true}
            width={'80%'}
            closeIcon={<div className={styles.dashboard_top_bar_mobile_menu_icon}><CloseOutlined /></div>}
            open={isDashboardSidebarDrawerVisible}
            onClose={() => setIsDashboardSidebarDrawerVisible(false)} >
            <div style={{width:'80%'}}>
                <DashboardSidebar clickLogin={clickLogin} setIsDashboardSidebarDrawerVisible={setIsDashboardSidebarDrawerVisible} />
            </div>
        </Drawer>
    )
}

export default DashboardSidebarDrawer;