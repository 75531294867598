import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ContentServices from "../../../../utils/ContentServices";
import { Helmet } from "react-helmet-async";
import NavbarMain from "../../../../components/Navbar/NavbarMain";
import TextEditorView from "../../../../components/Editor/TextEditorView";
import { Alert, Avatar, Skeleton } from "antd";
import ColorTag from "../../../../components/Tag/ColorTag";
import BlogPreview from "../../../../components/Preview/BlogPreview";
import BlogSkeleton from "../../../../components/Skeleton/BlogSkeleton";
import MainFooter from "../../../../components/Footer/MainFooter";
import { ExclamationCircleOutlined, LeftOutlined } from "@ant-design/icons";
import ParagraphSkeleton from "../../../../components/Skeleton/ParagraphSkeleton";

function BlogPage(){
    let { brand_key,blog_name } = useParams();
    const navigate = useNavigate();
    const [blog,setBlog] = useState(null);
    const [relatedBlogsList,setRelatedBlogsList] = useState({});
    const [userBlogList,setUserBlogList] = useState(null);

    const getBlog = async () => {
        const blogResponse = await ContentServices.getBlogPost({brand_key:brand_key,blog_name:blog_name});
        setBlog(blogResponse.data);
        console.log(blogResponse.data);
        relatedBlogs(blogResponse.data?.category);
    }
    const relatedBlogs = async (categories) => {
        if(categories && categories.length > 0){
            categories.forEach(async (value) => {
                if(value?.key){
                    const relatedBlogResponse = await ContentServices.getBlogList({brand_id:blog?.brand_id?.id, category:value?.key});
                    setRelatedBlogsList(prev => {return{...prev,[value?.name]:relatedBlogResponse.results}});
                }
            });
        }
        if(blog?.brand_id?.id){
            const userBlogResponse = await ContentServices.getBlogList({brand_id:blog?.brand_id?.id});
            setUserBlogList(userBlogResponse.results);
        }
    }

    const clickBlog = (value) => {
        window.open(`/blog/${value?.brand_id?.key}/${value?.title}`);
    }
    const clickLaunch = () => {
        
    }

    useEffect(() => {
        getBlog();
    },[])

    return(
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`${blog?.title? blog?.title : blog_name} - eCalmersAI Blogs`}</title>
                <meta property="og:title" content={`${blog?.title? blog?.title : blog_name} - eCalmersAI Blogs`}/>
                <meta name="description" content={blog?.description ? blog?.description : `Learn more about eCalmersAI here. From idea to launch in record time. Your best ideas will not come fully formed. Jot down any ideas you have, execute on the ones you like. Learn from your actions and iterate. Take Notes with eCalmersAI. `} />
                <link rel="canonical" href="https://www.ecalmers.com" />
            </Helmet>
            <NavbarMain type='blog' />
            <div className="min-h-screen w-full bg-white pt-4 pb-12 text-left text-slate-800">
                <div className="w-full md:w-6/12 m-auto h-full">
                    <div className="mb-10">
                        <div className="mb-4">
                            <div className="mb-6">
                                <div className="text-xs text-slate-500 cursor-pointer hover:underline" onClick={() => navigate(`/blog`)}>
                                    <LeftOutlined /> Blogs
                                </div>
                            </div>
                            <div className="mb-4">
                                <div className="w-full bg-ecalmers-100 border-ecalmers-200 rounded-2xl p-2 text-ecalmers-full flex items-center gap-4">
                                    <div className="text-lg">
                                        <ExclamationCircleOutlined/>
                                    </div>
                                    <div>
                                        This Post is Still Pending...<br/>
                                        Not All Information may be Accurate!
                                    </div>
                                </div>
                            </div>
                            <div className="flex gap-1 flex-wrap mb-2">
                                {
                                    blog?.category && blog?.category.length > 0 &&
                                        blog?.category.map((value,index) => 
                                            <div key={`category-tag-${index}`} className="cursor-pointer" onClick={() => navigate(`/blog?category=${value}`)}>
                                                <ColorTag value={value.name} />
                                            </div>
                                        )
                                }
                            </div>
                            <div className="mb-2">
                                {
                                    blog?.title
                                    ? <h2 className="leading-tight m-0 mb-2">{blog?.title}</h2> 
                                    : <div className="pb-2"><Skeleton.Input className="rounded-lg" block active/></div>
                                }
                            </div>
                            <div>
                                {
                                    blog?.brand_id
                                    ?
                                        <div>
                                            <div className="mb-1 text-sm text-slate-500">
                                                Post By:
                                            </div>
                                            <div className="flex items-center gap-1 text-slate-600">
                                                <div>
                                                    <Avatar size='small' src={blog?.brand_id?.avatar?.image} className="border-ecalmers-full" shape="cirle" />
                                                </div>
                                                <div>
                                                    <h4 className="text-base m-0">
                                                        {blog?.brand_id?.name}
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                    :   <div>
                                            <Skeleton.Input className="rounded-lg" active/>
                                        </div>
                                }
                            </div>
                        </div>
                        {
                            blog?.image_id?.image
                            ?
                                <div className="mb-12">
                                    <img src={blog?.image_id?.image} />
                                </div>
                            :   <div className="pb-2 mb-12 aspect-video overflow-hidden rounded-lg">
                                    <Skeleton.Input className="rounded-lg" style={{height:'50vh'}} block active/>
                                </div>
                        }
                        <div className="pb-20 text-base leading-normal">
                            {
                                blog?.content && false
                                ?
                                    <TextEditorView value={blog?.content} />
                                :
                                    [1,2,3,4,5].map((_,index) => 
                                        <div key={`paragraph-skeleton-${index}`} className="mb-12">
                                            <div className="mb-2">
                                                <Skeleton.Input className="rounded-lg" block active/>
                                            </div>
                                            <ParagraphSkeleton/>
                                        </div>
                                    )
                            }
                        </div>
                    </div>
                </div>
                <div className="w-full md:w-10/12 m-auto">
                    {
                        relatedBlogsList && Object.keys(relatedBlogsList).length > 0 &&
                            Object.keys(relatedBlogsList).map((key) => 
                                <div key={`related-row-${key}`} className="mb-10">
                                    <div className="mb-2">
                                        <h2>
                                            {key}
                                        </h2>
                                    </div>
                                    <div className="grid grid-cols-3 flex flex-wrap gap-2">
                                        {
                                            relatedBlogsList[key] && relatedBlogsList[key].length > 0 &&
                                                relatedBlogsList[key].map((value,index) => 
                                                    <div key={`blog-preview-${key}-${index}`} className="w-full bg-white rounded-lg text-left shadow-md text-slate-600" onClick={() => clickBlog(value)}>
                                                        <BlogPreview values={value} />
                                                    </div>
                                                )
                                        }
                                    </div>
                                </div>                            
                            )
                    }
                    <div>
                        <div className="mb-2">
                            <h2>
                                More Posts By {blog?.brand_id?.name ? blog?.brand_id?.name : brand_key}
                            </h2>
                        </div>
                        <div className="grid grid-cols-3 flex flex-wrap gap-2">
                            {
                                userBlogList && userBlogList.length > 0
                                ?
                                    userBlogList.map((value,index) => 
                                        <div key={`user-blog-preview-${index}`} className="w-full bg-white rounded-lg text-left shadow-md text-slate-600" onClick={() => clickBlog(value)}>
                                            <BlogPreview values={value} />
                                        </div>
                                    )
                                :   [1,2,3,4,5,6].map((_,index) => 
                                        <div key={`user-blog-skeleton-${index}`}>
                                            <BlogSkeleton />
                                        </div>
                                    )
                            }
                        </div>
                    </div>
                </div>
                <div className="pt-28">
                    <div className="w-full md:w-10/12 m-auto">
                        <MainFooter clickLaunch={clickLaunch} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlogPage;