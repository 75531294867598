import { MenuUnfoldOutlined,LeftOutlined } from '@ant-design/icons';
import NotificationButton from '../../../components/Button/NotificationButton';
import SearchInput from '../../../components/Search/SearchInput';
import styles from '../dashboard.module.css';
import DashboardUser from './DashboardUser';
import DashboardSidebarDrawer from './DashboardSidebarDrawer';
import { useState } from 'react';
import { useDashboardContext } from '../../../components/Context/DashboardContext';
import { Avatar, Button } from 'antd';
import noImage from '../../../image/no_image.webp';
import { useAuth } from '../../../utils/useAuth';

const titleValue = {
    'feeds':'My Feeds',
    'launch':'Launch',
    'content':'My Content',
    'channels':'My Channels',
    'explore':'Explore',
    'create':'Create',
    'notes':'Notes',
    'brands':'Brands',
    'settings':'Settings'
}
function DashboardNavbar({clickLogin}) {
    const {user} = useAuth();
    const {activeTab,selectedBrand,changeTab} = useDashboardContext();
    const [isDashboardSidebarDrawerVisible,setIsDashboardSidebarDrawerVisible] = useState(false);

    return(
        <div className='h-[65px] bg-white z-0 w-full flex items-center'>
            <div className={styles.dashboard_top_bar_mobile_menu_icon_container}>
                <div className={styles.dashboard_top_bar_mobile_menu_icon} onClick={() => setIsDashboardSidebarDrawerVisible(true)}>
                    <MenuUnfoldOutlined />
                </div>
            </div>
            <div className={styles.dashboard_top_bar_title_container}>
                {
                    !Object.keys(titleValue).includes(activeTab) && selectedBrand
                    ? 
                        <div className='display-flex'>
                            <div className='text-slate-800' onClick={() => changeTab('content')}>
                                <LeftOutlined />
                            </div>
                            <div style={{paddingLeft:'10px'}}>
                                <Avatar className={styles.dashboard_user_avatar} shape="square" src={selectedBrand?.avatar?.image ? selectedBrand?.avatar?.image : noImage} />
                            </div>
                            <div className={styles.dashboard_username} style={{paddingLeft:'5px'}}>
                                {selectedBrand?.name}
                            </div>
                        </div>
                    : <h2 className={styles.dashboard_top_bar_title}>{titleValue[activeTab]}</h2>
                }
                
            </div>
            <div className={styles.dashboard_top_bar_search_container}>
                <div className={styles.dashboard_top_bar_search}>
                    <SearchInput />
                </div>
            </div>
            <div className={styles.dashboard_top_bar_menu_container}>
                <div className={styles.dashboard_top_bar_notification_container}>
                    <NotificationButton active={false} />
                </div>
                <div className={styles.dashboard_top_bar_user_container}>
                    {
                        user
                        ? <DashboardUser />
                        : <Button type="primary" className="no-mobile rounded-full" onClick={clickLogin} style={{border:'1px solid #5662e2',backgroundColor:'#5662e2',color:'white'}}>Login/Register</Button>
                    }
                </div>
            </div>
            <div className="no-desktop">
                <DashboardSidebarDrawer clickLogin={clickLogin} isDashboardSidebarDrawerVisible={isDashboardSidebarDrawerVisible} setIsDashboardSidebarDrawerVisible={setIsDashboardSidebarDrawerVisible} />
            </div>
        </div>
    )
}

export default DashboardNavbar;