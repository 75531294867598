import { useEffect, useState } from "react";
import NoImage from "./NoImage";
import { Node } from 'slate';
import ColorTag from "../Tag/ColorTag";
import { Avatar } from "antd";

function BlogPreviewWide({values}) {
    const [textPreview,setTextPreview] = useState('');

    const setupPreview = () => {
        if(typeof values?.content == 'string'){
            setTextPreview(JSON.parse(values?.content).map(n => Node.string(n)).join(' '));
        }
        else {
            setTextPreview(values?.content.map(n => Node.string(n)).join(' '));
        }
    }

    useEffect(() => {
        if(values?.content){
            setupPreview();
        }
    },[values?.content]);

    return(
        <div className="border-b border-slate-100 shadow-sm p-8 h-full w-full cursor-pointer">
            <div className="flex items-center grid grid-cols-5 w-11/12 m-auto">
                <div className="col-span-4">
                    <div className="px-1 pt-2 pb-4 w-10/12">
                        <div>
                            {
                                values?.category && values?.category.length > 0 &&
                                    <div className="mb-1">
                                        {
                                            values?.category.map((value,index) => 
                                                <div key={`category-${index}`}>
                                                    <ColorTag value={value?.name} />
                                                </div>
                                            )
                                        }
                                    </div>
                            }
                        </div>
                        <div className="mb-2">
                            <h2 className="m-0 font-semibold leading-tight text-2xl">
                                {values?.title}
                            </h2>
                        </div>
                        <div className="text-slate-400 leading-tight font-base">
                            {textPreview.slice(0,250)}...
                        </div>
                    </div>
                </div>
                <div className="w-full">
                    {
                        values?.image_id
                        ?   <div className="bg-cover bg-center bg-no-repeat w-full aspect-video rounded-md" style={{backgroundImage:`url(${values?.image_id?.image})`}}></div>
                        :   <div className="w-full aspect-video border rounded-md">
                                <NoImage />
                            </div>
                    }
                </div>

                <div className="flex items-center">
                    <div className="flex items-center gap-1">
                        {
                            values?.brand_id?.avatar?.image
                            ? <Avatar size={'small'} shape="circle" src={values?.brand_id?.avatar?.image} className="border border-slate-100" />
                            : <NoImage/>
                        }
                        <div className="text-sm text-slate-600">
                            {values?.brand_id?.name}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlogPreviewWide;