import { useEffect, useState } from "react";
import NoImage from "./NoImage";
import { Node } from 'slate';
import ColorTag from "../Tag/ColorTag";

function BlogPreview({values}) {
    const [textPreview,setTextPreview] = useState('');

    const setupPreview = () => {
        if(typeof values?.content == 'string'){
            setTextPreview(JSON.parse(values?.content).map(n => Node.string(n)).join(' '));
        }
        else {
            setTextPreview(values?.content.map(n => Node.string(n)).join(' '));
        }
    }

    useEffect(() => {
        if(values?.content){
            setupPreview();
        }
    },[values?.content]);

    return(
        <div className="border border-slate-100 shadow-sm rounded-xl p-4 h-full w-full cursor-pointer">
            <div className="hover-scale-xs h-full">
                <div className="w-full">
                    {
                        values?.image_id
                        ?   <div className="bg-cover bg-center bg-no-repeat w-full aspect-video rounded-md" style={{backgroundImage:`url(${values?.image_id?.image})`}}></div>
                        :   <div className="w-full aspect-video border rounded-md">
                                <NoImage />
                            </div>
                    }
                </div>
                <div className="px-1 pt-2 pb-4">
                    <div className="mb-1">
                        <h3 className="m-0 font-semibold leading-snug">{values?.title}</h3>
                    </div>
                    {
                        values?.category && values?.category.length > 0 &&
                            <div className="mb-1">
                                {
                                    values?.category.map((value,index) => 
                                        <div key={`category-${index}`}>
                                            <ColorTag value={value?.name} />
                                        </div>
                                    )
                                }
                            </div>
                    }
                    <div className="text-slate-400 leading-tight">
                        {textPreview.slice(0,150)}...
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlogPreview;