import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";

function SearchInput({onSubmit}) {
    return(
        <Input 
            placeholder="Search Here..."
            className="search-input"
            size="large"
            onSubmit={onSubmit}
            prefix={<SearchOutlined style={{color:'rgba(86,98,226,0.8)'}} />}
            bordered={false}
            />
    )
}

export default SearchInput;