import { createContext, useContext, useMemo } from "react";
import { useLocalStorage } from "../../utils/useLocalStorage";
import { useAuth } from "../../utils/useAuth";
import { useNavigate } from "react-router-dom";

const DashboardContext = createContext();

export const DashboardProvider = ({ children }) => {
    const {logout} = useAuth();
    const [activeTab, setActiveTab] = useLocalStorage("activeTab","feeds");
    const [feedList,setFeedList] = useLocalStorage("userFeeds",null);
    const [brandList,setBrandList] = useLocalStorage("userBrands",null);
    const [feedSelected, setFeedSelected] = useLocalStorage("feedSelected",null);
    const [selectedBrand, setSelectedBrand] = useLocalStorage("selectedBrand",null);
    const [showSidebar, setShowSidebar] = useLocalStorage("sidebar",true);
    const [launchType, setLaunchType] = useLocalStorage("launchType",null);
    const [isLaunchModalVisible, setIsLaunchModalVisible] = useLocalStorage("launchModal",false);
    const navigate = useNavigate();

    const clearLocalStorage = () => {
        setActiveTab('feeds');
        setFeedList(null);
        setBrandList(null);
        setFeedSelected(null);
    }

    const toggleSidebar = () => {
        setShowSidebar(prev => !prev);
    }
    const changeTab = async (value) => {
        if(value != 'logout'){
            setActiveTab(value);
            navigate(value);
        }
        else {
            await clearLocalStorage();
            logout();
        }
    }
    const clickLaunch = (value) => {
        setLaunchType(value);
        setIsLaunchModalVisible(true);
    }
    
    const value = useMemo(
        () => ({
            activeTab,setActiveTab,changeTab,
            feedList,setFeedList,
            feedSelected, setFeedSelected,
            selectedBrand, setSelectedBrand,
            brandList,setBrandList,
            showSidebar, toggleSidebar,
            launchType, setLaunchType,
            isLaunchModalVisible, setIsLaunchModalVisible,
            clickLaunch
        }),[activeTab,feedSelected,selectedBrand,brandList,feedList,showSidebar,isLaunchModalVisible,launchType,clickLaunch]
    );
    
    return <DashboardContext.Provider value={value}>{children}</DashboardContext.Provider>;
};

export const useDashboardContext = () => {
    return useContext(DashboardContext);
};