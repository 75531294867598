import { Tag } from "antd";

const colorPalette = {
    "a": "#FF4500",  // OrangeRed
    "b": "#32CD32",  // LimeGreen
    "c": "#1E90FF",  // DodgerBlue
    "d": "#FF1493",  // DeepPink
    "e": "#8A2BE2",  // BlueViolet
    "f": "#FF8C00",  // DarkOrange
    "g": "#20B2AA",  // LightSeaGreen
    "h": "#00CED1",  // DarkTurquoise
    "i": "#FF6347",  // Tomato
    "j": "#3CB371",  // MediumSeaGreen
    "k": "#4682B4",  // SteelBlue
    "l": "#FF69B4",  // HotPink
    "m": "#9400D3",  // DarkViolet
    "n": "#FF7F50",  // Coral
    "o": "#66CDAA",  // MediumAquamarine
    "p": "#40E0D0",  // Turquoise
    "q": "#DC143C",  // Crimson
    "r": "#2E8B57",  // SeaGreen
    "s": "#4169E1",  // RoyalBlue
    "t": "#FF00FF",  // Magenta
    "u": "#800080",  // Purple
    "v": "#FF4500",  // OrangeRed
    "w": "#00FA9A",  // MediumSpringGreen
    "x": "#00BFFF",  // DeepSkyBlue
    "y": "#FF69B4",  // HotPink
    "z": "#8B0000"   // DarkRed
}

function ColorTag({value,onClick=false}){

    return(
        <Tag color={colorPalette[value.slice(0,1).toLowerCase()]} className="hover-scale-xs" onClick={() => onClick && onClick(value)} style={{borderRadius:'25px',marginLeft:0,marginRight:'5px'}}>{value}</Tag>
    )
}
export default ColorTag;