import React, { useMemo, useCallback, useEffect } from 'react';
import '../../style/index.css';
import { Editor, createEditor, Transforms } from 'slate';
import { Slate, Editable, withReact, useSlateStatic, useSelected, useFocused, ReactEditor } from 'slate-react';
import { withHistory } from 'slate-history'

function TextEditorView({value}){
    const renderElement = useCallback(props => <Element {...props} />, []);
    const renderLeaf = useCallback(props => <Leaf {...props} />, []);
    const editorView = useMemo(() => withHistory(withReact(createEditor())), []);

    const setNewContent = async () => {
        if(editorView.children.length > 0){
            await editorView.children.map(item => {
                Transforms.delete(editorView, { at: [0] })
            });
        }
        if(value){
            const textValues = typeof value == 'string' ? await JSON.parse(value) : value;
            editorView.children = textValues;
        }
    }

    useEffect(() => {
        setNewContent();
    },[value])

    return(
        <Slate editor={editorView} className='w-full h-full' initialValue={isJsonString(value) ? JSON.parse(value) : value}>
            <Editable 
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                readOnly={true} />
        </Slate>
    );
};

export default TextEditorView;

const isJsonString = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

const Element = ({ attributes, children, element }) => {
    const style = { textAlign: element.align, marginBottom:'3px' }
    switch (element.type) {
    case 'block-quote':
        return (
            <blockquote style={style} {...attributes}>
                {children}
            </blockquote>
        )
    case 'bulleted-list':
        return (
            <ul className='list-disc pl-4' style={style} {...attributes}>
                {children}
            </ul>
        )
    case 'heading-one':
        return (
        <h1 className='leading-tight' style={style} {...attributes}>
            {children}
        </h1>
        )
    case 'heading-two':
        return (
            <h2 className='leading-tight' style={style} {...attributes}>
                {children}
            </h2>
        )
    case 'heading-three':
        return (
            <h3 className='leading-tight font-medium' style={style} {...attributes}>
                {children}
            </h3>
        )
    case 'heading-four':
        return (
            <h4 style={style} {...attributes}>
                {children}
            </h4>
        )
    case 'list-item':
        return (
            <li className='pl-4' style={style} {...attributes}>
                {children}
            </li>
        )
    case 'numbered-list':
        return (
            <ol className='list-decimal' style={style} {...attributes}>
                {children}
            </ol>
        )
    case 'image':
        return (
            <>
                <Image attributes={attributes} children={children} element={element} />
            </>
        )
    default:
        return (
            <p style={style} {...attributes}>
                {children}
            </p>
        )
    }
}

const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>
    }

    if (leaf.code) {
        children = <code>{children}</code>
    }

    if (leaf.italic) {
        children = <em>{children}</em>
    }

    if (leaf.underline) {
        children = <u>{children}</u>
    }

    return <span {...attributes}>{children}</span>
}


const Image = ({ attributes, children, element }) => {
    const editor = useSlateStatic()
    const path = ReactEditor.findPath(editor, element)
  
    const selected = useSelected()
    const focused = useFocused()
    return (
        <div {...attributes}>
            {children}
            <div
            contentEditable={false}
            style={{position: 'relative'}}
            >
                <img
                    src={element.url}
                    style={{
                        'display': 'block',
                        'maxWidth': '100%',
                        'maxHeight': '20em',
                        'boxShadow': selected && focused ? '0 0 0 3px #B4D5FF' : 'none'
                    }}
                    />
            </div>
        </div>
    )
}  