import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

function BlogsPage(){
    const [content,setContent] = useState(null);

    const getBlogs = async () => {

    }

    useEffect(() => {
        getBlogs();
    },[])
    return(
        <div>

        </div>
    )
}

export default BlogsPage;