import { useEffect, useState } from "react";
import ContentServices from "../../../../utils/ContentServices";
import { Helmet } from "react-helmet-async";
import NavbarMain from "../../../../components/Navbar/NavbarMain";
import BlogPreview from "../../../../components/Preview/BlogPreview";
import { useSearchParams } from "react-router-dom";
import BlogPreviewWide from "../../../../components/Preview/BlogPreviewWide";
import { Pagination, Skeleton, message } from "antd";
import { RightOutlined } from "@ant-design/icons";
import BlogSkeleton from "../../../../components/Skeleton/BlogSkeleton";

function EcalmersBlogsPage(){
    const [searchParams,setSearchParams] = useSearchParams();
    const category = searchParams.get('category');
    const page = searchParams.get('p');
    const [loading,setLoading] = useState(false);
    const [categories,setCategories] = useState(null);
    const [blogList,setBlogList] = useState(null);
    const [count,setCount] = useState(null);
    
    const capitalizeFirstLetter = (string) => {
        return string.split('_').map((value) => {return value.charAt(0).toUpperCase() + value.slice(1)}).join(' ');
    }
    const getBlogCategories = async () => {
        const blogCategoryResponse = await ContentServices.getBlogCategoryList({brand_id:1});
        setCategories(blogCategoryResponse.data);
    }
    const getBlogs = async ({pageValue,categoryValue}) => {
        window.scrollTo({ top: 0, behavior: 'smooth',});
        setLoading(true);
        setBlogList(null);
        const queryPage = pageValue ? pageValue - 1 : 0;
        const blogsResponse = await ContentServices.getBlogList({brand_id:1,page:queryPage,category:categoryValue});
        setBlogList(blogsResponse.results);
        if(count === null){
            setCount(blogsResponse.count);
        }
        setLoading(false);
    }
    const clickBlog = (value) => {
        window.open(`/blog/${value?.brand_id?.key}/${value?.title}`);
    }
    const clickCategory = (value) => {
        if(!loading){
            if(value){
                searchParams.set('category',value);
                setSearchParams(searchParams);
            }
            else{
                searchParams.delete('category');
                setSearchParams(searchParams);
            }
            getBlogs({pageValue:page,categoryValue:value});
        }
        else{
            message.info('One Sec...');
            window.scrollTo({ top: 0, behavior: 'smooth',});
        }
    }
    const clickPage = (value) => {
        if(!loading){
            searchParams.set('p',value);
            setSearchParams(searchParams);
            getBlogs({pageValue:value,categoryValue:category});
        }
        else {
            message.info('One Sec...');
            window.scrollTo({ top: 0, behavior: 'smooth',});
        }
    }

    useEffect(() => {
        getBlogCategories();
    },[]);

    useEffect(() => {
        const category = searchParams.get('category');
        const p = searchParams.get('p');
        getBlogs({pageValue:p, categoryValue:category});
    },[]);

    return(
        <div className="w-screen bg-white">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`Welcome to Our Blog - eCalmersAI`}</title>
                <meta property="og:title" content={`Welcome to Our Blog - eCalmersAI`}/>
                <meta name="description" content={`Learn more about eCalmersAI here. From idea to launch in record time. Your best ideas will not come fully formed. Jot down any ideas you have, execute on the ones you like. Learn from your actions and iterate. Take Notes with eCalmersAI. `} />
                <link rel="canonical" href="https://www.ecalmers.com" />
            </Helmet>
            <NavbarMain type='blog' />
            <div className="w-full sticky top-[65px] z-10 bg-white">
                <div className="w-full md:w-8/12 m-auto text-left border-b border-slate-100">
                    <div className="flex overflow-x-scroll hide-scrollbar pb-4 w-10/12 m-auto text-base text-slate-500 gap-8">
                        {
                            categories && categories.length > 0 &&
                                <div onClick={() => clickCategory(null)} className={`cursor-pointer w-fit text-nowrap py-2 hover-scale-xs hover:text-slate-800 ${category === null && 'font-semibold underline'}`}>
                                    Recommended
                                </div>
                        }
                        {
                            categories && categories.length > 0
                            ?
                                categories.map((value,index) => 
                                    <div key={`category-tag-${index}`} onClick={() => clickCategory(value.key)} className={`cursor-pointer w-fit text-nowrap py-2 hover-scale-xs hover:text-slate-800 ${category === value.key && 'font-semibold underline'}`}>
                                        {capitalizeFirstLetter(value.name)}
                                    </div>
                                )
                            :
                                [1,2,3,4,5,6,7].map((_,index) =>
                                    <div key={`category-load-${index}`}>
                                        <Skeleton.Input active className="rounded-lg" />
                                    </div>
                                )
                        }
                    </div>
                    <div>
                        <RightOutlined />
                    </div>
                </div>
            </div>
            <div className="min-h-screen w-full md:w-8/12 m-auto pb-36">
                <div>
                    {
                        blogList && blogList.length > 0
                        ?
                            blogList.map((value,index) => 
                                <div key={`blog-preview-${index}`} className="w-full text-left text-slate-600 hover-scale-xs p-4 md:p-0" onClick={() => clickBlog(value)}>
                                    {
                                        window.innerWidth > 1000
                                        ? <BlogPreviewWide values={value}/>
                                        : <BlogPreview values={value}/>
                                    }
                                </div>
                            )
                        :
                            [1,2,3,4,5,6,7].map((_,index) =>
                                <div key={`blog-load-${index}`}>
                                    <BlogSkeleton type={window.innerWidth > 1000 ? 'wide' : false} />
                                </div>
                            )
                    }
                </div>
                <div className="py-8">
                    {
                        count &&
                            <Pagination pageSize={18} total={count} onChange={(value) => clickPage(value)} showSizeChanger={false} />
                    }
                </div>
            </div>
        </div>
    )
}

export default EcalmersBlogsPage;