import { Skeleton, Space } from "antd";
import ParagraphSkeleton from "./ParagraphSkeleton";

function BlogSkeleton({type=false}){
    return(
        type && type === 'wide'
        ?
            <div className="bg-white border-b border-slate-100 rounded-xl p-4 h-full w-full flex grid grid-cols-5 items-center">
                <div className="post-instagram-content-container col-span-4 w-10/12 m-auto">
                    <ParagraphSkeleton />
                </div>
                <div className="p-2">
                    <div className="aspect-video w-full overflow-hidden">
                        <Skeleton.Input style={{height:'50vh'}} active={true} block={true} />
                    </div>
                </div>
            </div>
        :
            <div className="bg-white border border-slate-100 shadow-md rounded-xl p-4 h-full w-full">
                <div className="p-2">
                    <div className="aspect-video w-full overflow-hidden">
                        <Skeleton.Input style={{height:'50vh'}} active={true} block={true} />
                    </div>
                </div>
                <div className="post-instagram-content-container">
                    <ParagraphSkeleton />
                </div>
            </div>
    )
}

export default BlogSkeleton;