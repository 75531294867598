import styles from '../dashboard.module.css';
import { Space } from "antd";

function DashboardButton({name,icon,active,onClick,showSidebar=false}) {
    return(
        <div className={`${styles.dashboard_menu_button} ${active && styles.active}`} onClick={onClick}>
            <Space size={'large'}>
                <div className={styles.dashboard_menu_button_icon}>
                    {icon}
                </div>
                {
                    showSidebar &&
                        <div>
                            {name}
                        </div>
                }
            </Space>
        </div>
    )
}

export default DashboardButton;