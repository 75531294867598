import React, { useEffect, useState }  from 'react';
import { Modal, Tabs, message } from "antd";
import LoginForm from '../Form/LoginForm';
import RegistrationForm from '../Form/RegistrationForm';
import { useAuth } from '../../utils/useAuth';
import logo from '../../image/logo/logo512.png';
import noImage from '../../image/no_image.webp';

function LoginModal({isLoginModalVisible, closeModal,closable=true,startTab='login', onRegistration=false, claim=false}){
    const {user} = useAuth();
    const [activeKey, setActiveKey] = useState(startTab);
    
    const focusInput = async () => {
        const element = document.getElementById('username-input');
        if(element){
            const fieldElement = document.getElementById('username-input-div');
            await fieldElement.classList.remove("bounce-sm");
            element.focus();
            fieldElement.classList.add("bounce-sm");
        }
        else{
            setTimeout(() => {
                focusInput();
            },500)
        }
    }
    useEffect(() => {
        if(user && isLoginModalVisible){
            message.info("You're Already Logged in!");
            closeModal();
        }
        else{
            focusInput();
        }
    },[isLoginModalVisible]);

    return(
        <Modal             
            open={isLoginModalVisible} 
            footer={null} 
            onCancel={closeModal}
            width={'100%'}
            style={{maxWidth:'420px',borderRadius:'15px',overflow:'hidden',marginBottom:'5vh'}}
            centered
            closable={closable}
            destroyOnClose
            >
            <div className="modal-div px-6 py-10">
                <div className='w-full'>
                    <div className='text-center mb-4'>
                        {
                            activeKey === 'login'
                            ? <p className='login-page-title' style={{marginBottom:'5px'}}>Login</p>
                            : <p className='login-page-title leading-8 pb-2'>Create an Account</p>
                        }
                    </div>
                    <div className='mb-2'>
                        {
                            activeKey === 'login'
                            ? <LoginForm />
                            : <RegistrationForm onSuccess={onRegistration} />
                        }
                    </div>
                    <div className='text-slate-400 cursor-pointer mb-8 text-sm text-center'>
                        {
                            activeKey === 'login'
                            ?   <div className='text-slate-800 underline text-center' onClick={() => setActiveKey('register')} >
                                    Create an Account
                                </div>
                            :   <div className='underline' onClick={() => setActiveKey('login')}>
                                    Already have an account
                                </div>
                        }
                    </div>
                    {
                        claim &&
                            <div className='bg-ecalmers-50 border border-ecalmers-500 p-4 rounded-2xl mb-2 shadow-[1px_1px_0_0_rgba(86,98,226,1)]'>
                                <div className='flex items-start'>
                                    <div className='mr-2'>
                                        <img src={claim?.avatar?.image ? claim?.avatar?.image : noImage} className='w-10 border rounded-lg' />
                                    </div>
                                    <div className='text-ecalmers-full leading-4'>
                                        <div className='text-xs'>Register to Claim This Brand.</div>
                                        <div className='text-slate-600 font-semibold'>{claim?.name}</div>
                                    </div>
                                </div>
                            </div>
                    }
                    <div className='m-auto w-fit'>
                        <img src={logo} className='w-14' />
                    </div>
                </div>
            </div>
        </Modal>
    )
}
export default LoginModal;