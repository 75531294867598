import { CodeOutlined, CoffeeOutlined, EditOutlined, FormOutlined, LineChartOutlined, MailOutlined, RiseOutlined, RocketOutlined, ShopOutlined, ShoppingCartOutlined, SmileOutlined, TeamOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Dropdown, Space } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/useAuth";


const launchItems = [
    {
        key:'1',
        label:(
            <div className="p-2 py-3 pt-4" style={{maxWidth:'350px'}}>
                <div className="mb-1">
                    <h4 className="m-0 leading-none font-semibold">Launch Socials <TeamOutlined className="text-ecalmers-full" /></h4>
                </div>
                <p className="leading-snug text-slate-600 text-sm">
                    Social Media Channels Launch in One-Click. Includes Profile Setup & 3 Months of Content!
                </p>
            </div>
        )
    },
    {
        key:'2',
        label:(
            <div className="p-2 py-3" style={{maxWidth:'350px'}}>
                <div className="mb-1">
                    <h4 className="m-0 leading-none font-semibold">Launch ProductHunt <CoffeeOutlined className="text-ecalmers-full" /></h4>
                </div>
                <p className="leading-snug text-slate-600 text-sm">
                    ProductHunt Launch in One-Click. Launch Your New Feature or Brand in Seconds.
                </p>
            </div>
        )
    },
    {
        key:'3',
        label:(
            <div className="p-2 py-3" style={{maxWidth:'350px'}}>
                <div className="mb-1">
                    <h4 className="m-0 leading-none font-semibold">Launch Blog <FormOutlined className="text-ecalmers-full" /></h4>
                </div>
                <p className="leading-snug text-slate-600 text-sm">
                    Blog Launch in One-Click. Start Gaining Organic Traffic. Jumpstart your Online Presencee.
                </p>
            </div>
        )
    },
    {
        key:'4',
        label:(
            <div className="p-2 py-3" style={{maxWidth:'350px'}}>
                <div className="mb-1">
                    <h4 className="m-0 leading-none font-semibold">Launch Email <MailOutlined className="text-ecalmers-full" /></h4>
                </div>
                <p className="leading-snug text-slate-600 text-sm">
                    Email Launch in One-Click. Setup a Full Email Marketing System. 
                </p>
            </div>
        )
    },
]

const productItems = [
    {
        key:'1',
        label:(
            <div className="p-2 py-3 pt-4" style={{maxWidth:'350px'}}>
                <div className="mb-1">
                    <h4 className="m-0 leading-none font-semibold">Launch A Product <RocketOutlined className="text-ecalmers-full" /></h4>
                </div>
                <p className="leading-snug text-slate-600 text-sm">
                    One-Click Launch for Your Brand. Setup Your Socials, Blog Posts, Email and More in Minutes.
                </p>
            </div>
        )
    },
    {
        key:'2',
        label:(
            <div className="p-2 py-3" style={{maxWidth:'350px'}}>
                <div className="mb-1">
                    <h4 className="m-0 leading-none font-semibold">Create Posts <EditOutlined className="text-ecalmers-full" /></h4>
                </div>
                <p className="leading-snug text-slate-600 text-sm">
                    Create and Manage Your Blog Posts with Ease. Turn Your Ideas into Content for All Your Channels.
                </p>
            </div>
        )
    },
    {
        key:'4',
        label:(
            <div className="p-2 py-3" style={{maxWidth:'350px'}}>
                <div className="mb-1">
                    <h4 className="m-0 leading-none font-semibold">Write Blogs & Emails <MailOutlined className="text-ecalmers-full" /></h4>
                </div>
                <p className="leading-snug text-slate-600 text-sm">
                    Increase Organic Traffic and User Retention with Blogs and Emails. Setup and Iterate on Your Content to Grow Your Users.
                </p>
            </div>
        )
    },
    {
        key:'3',
        label:(
            <div className="p-2 py-3" style={{maxWidth:'350px'}}>
                <div className="mb-1">
                    <h4 className="m-0 leading-none font-semibold">Explore Trends <LineChartOutlined className="text-ecalmers-full" /></h4>
                </div>
                <p className="leading-snug text-slate-600 text-sm">
                    Explore the Latest Trends to Stay Relevant. Boost Creativity and Finding New Ideas.
                </p>
            </div>
        )
    }
];

const applicationItems = [
    {
        key:'1',
        label:(
            <div className="p-2 py-3 pt-4" style={{maxWidth:'350px'}}>
                <div className="mb-1">
                    <h4 className="m-0 leading-none font-semibold">For Small Businesses <ShopOutlined className="text-ecalmers-full" /></h4>
                </div>
                <p className="leading-snug text-slate-600 text-sm">
                    We Help Small Businesses Save Money and Time on Marketing Costs. Creating an Experience for Everyone.
                </p>
            </div>
        )
    },
    {
        key:'2',
        label:(
            <div className="p-2 py-3" style={{maxWidth:'350px'}}>
                <div className="mb-1">
                    <h4 className="m-0 leading-none font-semibold">For eCommerce <ShoppingCartOutlined className="text-ecalmers-full" /></h4>
                </div>
                <p className="leading-snug text-slate-600 text-sm">
                    We Make Testing New Products and Expanding Online Presence for eCommerce Businesses Easier and Faster.
                </p>
            </div>
        )
    },
    {
        key:'3',
        label:(
            <div className="p-2 py-3" style={{maxWidth:'350px'}}>
                <div className="mb-1">
                    <h4 className="m-0 leading-none font-semibold">For Startups <CodeOutlined className="text-ecalmers-full" /></h4>
                </div>
                <p className="leading-snug text-slate-600 text-sm">
                    We Help Startups focus on building their product and business instead of spending valuable time and resources on marketing and setup.
                </p>
            </div>
        )
    },
    {
        key:'4',
        label:(
            <div className="p-2 py-3" style={{maxWidth:'350px'}}>
                <div className="mb-1">
                    <h4 className="m-0 leading-none font-semibold">For Personal Brands <UserOutlined className="text-ecalmers-full" /></h4>
                </div>
                <p className="leading-snug text-slate-600 text-sm">
                    We Help You establish your personal brand online by focusing on the ideas while we help with create.
                </p>
            </div>
        )
    },
    {
        key:'5',
        label:(
            <div className="p-2 py-3" style={{maxWidth:'350px'}}>
                <div className="mb-1">
                    <h4 className="m-0 leading-none font-semibold">For Good <SmileOutlined className="text-ecalmers-full" /></h4>
                </div>
                <p className="leading-snug text-slate-600 text-sm">
                    We Help Nonprofits and Important Causes boost their impact and reach by lowering costs and allowing content creation at scale.
                </p>
            </div>
        )
    },
];

function NavbarMain({type='logo'}) {
    const {user} = useAuth();
    const navigate = useNavigate();

    const clickLogin = () => {
        navigate('/login');
    }
    const clickDashboard = () => {
        navigate('/dashboard/create');
    }

    return(
        <div className='sticky top-0 left-0 right-0 w-full h-[65px] flex items-center px-4 bg-white' style={{zIndex:1}}>
            <div className="text-left flex-1">
                {
                    {
                        'logo':
                            <Link to='/'className="cursor-pointer">
                                <h2 className="text-ecalmers-full m-0 leading-none">eCalmers AI</h2>
                            </Link>,
                        'blog':
                            <Link to='/blog'className="cursor-pointer">
                                <h2 className="text-ecalmers-full m-0 leading-none">Our Blog</h2>
                            </Link>
                    }[type]
                }
            </div>
            <Space direction="horizontal" size='large' className="h-full">
                <Dropdown menu={{items:launchItems}} placement="bottomRight" className="no-mobile">
                    <div className="text-ecalmers-full text-base cursor-pointer h-full border-b-2 border-white transition duration-200 hover:border-[#5662e2]">Launch</div>
                </Dropdown>
                <Dropdown menu={{items:productItems}} placement="bottomRight" className="no-mobile">
                    <div className="text-ecalmers-full text-base cursor-pointer h-full border-b-2 border-white">Products</div>
                </Dropdown>
                <Dropdown menu={{items:applicationItems}} placement="bottomRight" className="no-mobile">
                    <div className="text-ecalmers-full text-base cursor-pointer h-full border-b-2 border-white">Applications</div>
                </Dropdown>
                {
                    user
                    ? <Button type="outline" className="bg-ecalmers-full text-white hover-scale-sm rounded-full" onClick={clickDashboard}>Back to Dashboard</Button>
                    : <Button type="outline" className="bg-ecalmers-full text-white hover-scale-sm rounded-full" onClick={clickLogin}>Login/Register</Button>
                }
            </Space>
        </div>
    )
}

export default NavbarMain;