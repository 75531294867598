import { Skeleton, Space } from "antd";

function ParagraphSkeleton({short=false}){
    return(
        <Space direction="vertical" size={2} className="w-full pt-2">
            {
                !short &&
                    <div style={{width:'40%'}}>
                        <Skeleton.Input active={true} style={{height:'18px'}} block={true} />
                    </div>
            }
            {
                short
                ?   <Skeleton.Input active={true} style={{height:'18px'}} block={true} />
                :   [1,2,3].map((_,index) => 
                        <div key={`skeleton-${index}`} style={{width:'100%'}}>
                            <Skeleton.Input active={true} style={{height:'18px'}} block={true} />
                        </div>
                    )
            }
            <div style={{width:'80%'}}>
                <Skeleton.Input active={true} style={{height:'18px'}} block={true} />
            </div>
        </Space>
    )
}

export default ParagraphSkeleton;